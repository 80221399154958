import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "close", "open", "menu" ]
  connect() {}

  toggle() {
    this.closeTarget.classList.toggle("hidden")
    this.openTarget.classList.toggle("hidden")
    this.menuTarget.classList.toggle("hidden")
  }
}
