import { Controller } from "stimulus"

let debounce = require('lodash/debounce');

export default class extends Controller {
  static targets = [ "submit", "query" ]

  initialize() {
    this.type = debounce(this.type, 500)
    this.focus()
  }

  type() {
    this.submitTarget.click()
  }

  focus() {
    const val = this.queryTarget.value
    this.queryTarget.value = ''
    this.queryTarget.value = val
  }
}
