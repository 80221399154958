import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "add", "content", "editableContent", "save" ]

  connect() {
    this.editableContentTarget.style.display = "none"

    if (this.hasAddTarget) {
      this.addTarget.style.display = "block"
    }
  }

  add() {
    this.editableContentTarget.style.display = "block"
    this.addTarget.style.display = "none"
  }

  cancel() {
    this.editableContentTarget.style.display = "none"
    if(this.editing) {
      this.contentTarget.style.display = "block"
    } else {
      this.addTarget.style.display = "block"
    }
  }

  edit() {
    this.editableContentTarget.style.display = "block"
    this.contentTarget.style.display = "none"
    this.editing = true
  }

  keyup(event) {
    if (event.ctrlKey && event.key === 'Enter') {
      this.saveTarget.click()
    }
  }
}
