import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "menu", "overlay" ]
  connect() {
    this.state = 'closed'
  }

  disconnect() {
    this.state = 'closed'
  }

  toggle() {
    if (this.state === 'open') {
      this.close()
    } else {
      this.open()
    }
  }

  close() {
    this.menuTarget.classList.add("hidden")
    this.overlayTarget.classList.add("hidden")
    document.removeEventListener('keydown', this.handleKeypress.bind(this))
    this.state = 'closed'
  }

  open() {
    this.menuTarget.classList.remove("hidden")
    this.overlayTarget.classList.remove("hidden")
    document.addEventListener('keydown', this.handleKeypress.bind(this))
    this.state = 'open'
  }

  handleKeypress(event) {
    if (event.key === 'Esc' || event.key === 'Escape') {
      this.close()
    }
  }
}
